import {Routes} from "@angular/router";
import {AdminAuthGuard} from "../../service/admin-auth-guard.service";

export const Admin_routes: Routes = [
	{path: '', redirectTo: 'order/chart', pathMatch: 'full'},
	{path: 'login', loadChildren: () => import('./LoginModule/Login.module').then(m => m.LoginModule)},
	{path: 'home_screen', loadChildren: () => import('./HomeScreenModule/home-screen.module').then(m => m.HomeScreenModule), canActivate: [AdminAuthGuard]},
	{path: 'static_page', loadChildren: () => import('./StaticPageModule/StaticPage.module').then(m => m.StaticPageModule), canActivate: [AdminAuthGuard]},
	{path: 'setting', loadChildren: () => import('./SettingModule/Setting.module').then(m => m.SettingModule), canActivate: [AdminAuthGuard]},
	{path: 'order', loadChildren: () => import('./OrderModule/Order.module').then(m => m.OrderModule), canActivate: [AdminAuthGuard]},
	{path: 'banner', loadChildren: () => import('./BannerModule/Banner.module').then(m => m.BannerModule), canActivate: [AdminAuthGuard]},
	{path: 'test', loadChildren: () => import('./TestModule/Test.module').then(m => m.TestModule), canActivate: [AdminAuthGuard]},
]