import {Injectable} from '@angular/core';
import {CommonService} from "./common.service";

@Injectable({providedIn: 'root'})
export class ToastService {

	callback = null;

	constructor(
		public common: CommonService,
	) {
	}


	/**
	 * type: success, error, <empty>
	 * @param {{ msg: string, interval: number | null, type: string | null }} data
	 */
	async add(data: {msg: string, interval?: number | null, type?: string | null}) {
		if (!this.callback) {
			await this.common.until(() => this.callback);
		}
		return this.callback(data);
	}
}