import {Component} from '@angular/core';
import {register} from "swiper/element/bundle";
import {TranslateService} from "@ngx-translate/core";
import {PrimeNGConfig} from "primeng/api";
import {ActivatedRoute} from "@angular/router";
import {SettingService} from "./service/setting.service";

register();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'test-angular';

  constructor(
      public trans: TranslateService,
      public primeng_config: PrimeNGConfig,
      public route: ActivatedRoute,
      public setting: SettingService,
  ) {
    this.trans.use(this.setting.lang).subscribe(() => {
      let translates = this.trans.instant('primeng_' + this.setting.lang);
      this.primeng_config.setTranslation(translates);
    })
  }

}
