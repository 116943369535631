import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {get_school_id} from "./school.service";

@Injectable({providedIn: 'root'})
export class UserService {

	profile_image_url = null;
	display_name = null;
	email = null;

	is_admin = false;

	constructor(
	) {
		this.init();
	}

	init() {
		this.profile_image_url = localStorage.getItem(this.storage_keys.profile_image_url) || null;
		this.display_name = localStorage.getItem(this.storage_keys.display_name) || null;
		this.email = localStorage.getItem(this.storage_keys.email) || null;

		if (this.profile_image_url === 'null') {
			this.profile_image_url = null;
		}
	}

	get storage_keys() {
		let school_id = get_school_id();
		if (this.is_admin) {
			return {
				profile_image_url: `admin_user_${school_id}_profile_image_url`,
				display_name: `admin_user_${school_id}_display_name`,
				email: `admin_user_${school_id}_email`,
			}
		}
		return {
			profile_image_url: `user_${school_id}_profile_image_url`,
			display_name: `user_${school_id}_display_name`,
			email: `user_${school_id}_email`,
		}
	}

	set_display_name(display_name) {
		this.display_name = display_name;
		localStorage.setItem(this.storage_keys.display_name, display_name);
	}

	set_profile_image_url(profile_image_url) {
		this.profile_image_url = profile_image_url;
		localStorage.setItem(this.storage_keys.profile_image_url, profile_image_url);

	}

	set_email(email) {
		this.email = email;
		localStorage.setItem(this.storage_keys.email, email);
	}

	clear_data() {
		localStorage.removeItem(this.storage_keys.profile_image_url);
		localStorage.removeItem(this.storage_keys.display_name);
		localStorage.removeItem(this.storage_keys.email);
		this.profile_image_url = null;
		this.display_name = null;
		this.email = null;
	}
}