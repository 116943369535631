import {Component, ElementRef} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ILoadingOverlayAngularComp} from 'ag-grid-angular';
import {ILoadingOverlayParams} from 'ag-grid-community';
import {ScriptService} from 'src/app/service/script.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'no-row-overlay',
	template: `
		<div class="image" *ngIf="image" [style.backgroundImage]="image" [style]="imageStyle"></div>
		<div class="text" *ngIf="text && (!text_index || text_index == 1)" [style]="textStyle">{{ trans.instant(text) }}</div>
		<div class="text" *ngIf="text2 && text_index == 2" [style]="textStyle">{{ trans.instant(text2) }}</div>
	`,
	styleUrls: ['./noRows.overlay.scss']
})

export class NoRowsOverlay implements ILoadingOverlayAngularComp {
	public params: any;
	public image;
	public imageStyle: any = {};
	public text;
	public text2;
	public textStyle: any = {};
	public text_index: any

	constructor(private sans: DomSanitizer, private script: ScriptService, private eleRef: ElementRef, public trans: TranslateService) {

	}

	agInit(params: ILoadingOverlayParams) {
		this.params = params;
		if (this.params.image) {
			this.image = this.sans.bypassSecurityTrustStyle("url('" + this.script.assetsUrl + 'img/' + this.params.image);
		} else if (this.params.image !== false) {
			this.image = this.sans.bypassSecurityTrustStyle("url('" + this.script.assetsUrl + 'img/profile/empty.svg');
		}
		if (this.params.imageStyle) {
			this.imageStyle = this.params.imageStyle;
		}
		if (this.params.text) {
			this.text = this.params.text;
		}
		if (this.params.text2) {
			this.text2 = this.params.text2;
		}
		if (this.params.text_index) {
			this.text_index = this.params.text_index;
		}
		if (this.params.textStyle) {
			this.textStyle = this.params.textStyle;
		}
		if (this.params.hostStyle) {
			let eleStyle = this.eleRef.nativeElement.style;
			let hostStyle = this.params.hostStyle;
			for (let prop in hostStyle) {
				eleStyle[prop] = hostStyle[prop];
			}
		}
	}

}