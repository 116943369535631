import {Routes} from "@angular/router";
import {ResetDescriptionGuard} from "src/app/service/reset-description-guard.service";

export const Shop_routes: Routes = [
	{path: 'login', loadChildren: () => import('./LoginPageModule/LoginPage.module').then(m => m.LoginPageModule), canActivate: [ResetDescriptionGuard]},
	{path: 'contact_us', loadChildren: () => import('./ContactUsPageModule/ContactUsPage.module').then(m => m.ContactUsPageModule), canActivate: [ResetDescriptionGuard]},
	{path: 'shopping_cart', loadChildren: () => import('./ShoppingCartPageModule/ShoppingCartPage.module').then(m => m.ShoppingCartPageModule), canActivate: [ResetDescriptionGuard]},
	{path: 'course', loadChildren: () => import('./CoursePageModule/CoursePage.module').then(m => m.CoursePageModule), canActivate: [ResetDescriptionGuard]},
	{path: 'ebook', loadChildren: () => import('./EbookPageModule/EbookPage.module').then(m => m.EbookPageModule), canActivate: [ResetDescriptionGuard]},
	{path: 'bookmark', loadChildren: () => import('./BookmarkPageModule/BookmarkPage.module').then(m => m.BookmarkPageModule), canActivate: [ResetDescriptionGuard]},
	{path: 'order', loadChildren: () => import('./OrderPageModule/OrderPage.module').then(m => m.OrderPageModule), canActivate: [ResetDescriptionGuard]},
	{path: 'my_account', loadChildren: () => import('./MyAccountPageModule/MyAccountPage.module').then(m => m.MyAccountPageModule), canActivate: [ResetDescriptionGuard]},
	{path: 'search', loadChildren: () => import('./SearchPageModule/SearchPage.module').then(m => m.SearchPageModule), canActivate: [ResetDescriptionGuard]},
	{path: ':unique_key', loadChildren: () => import('./StaticPageModule/StaticPage.module').then(m => m.StaticPageModule), canActivate: [ResetDescriptionGuard]},
	{path: '', loadChildren: () => import('./HomePageModule/HomePage.module').then(m => m.HomePageModule), canActivate: [ResetDescriptionGuard]},
];