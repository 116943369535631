import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {CommonService} from "./common.service";
import {ApiService} from "./api.service";

@Injectable({providedIn: 'root'})
export class SettingService {

	public lang = 'tc';
	public lang_list = [
		{key: 'tc', label: '繁體中文', short_label: '繁'},
		{key: 'sc', label: '简体中文', short_label: '简'},
		{key: 'en', label: 'English', short_label: 'EN'},
	];

	website_title = null;

	constructor(
		public trans: TranslateService,
		public common: CommonService,
		public api: ApiService,
	) {

	}

	set_lang(lang) {
		this.lang = lang;
		this.trans.use(lang);
		this.api.lang = lang;
	}

	get next_lang() {
		let current_index = this.lang_list.findIndex(lang => lang.key === this.lang);
		let next_index = current_index + 1;
		if (next_index >= this.lang_list.length) {
			next_index = 0;
		}
		return this.lang_list[next_index].key;
	}

	get is_lang_en() {
		return this.lang === 'en';
	}

	set_title(title = null, website_title = null) {
		// console.log(this.trans.currentLang);
		title = title ? title + ' - ' : '';
		this.trans.get('common.shop_title').subscribe(translated_str => {
			if (!website_title) {
				if (!this.website_title) {
					website_title = translated_str;
				} else {
					website_title = this.website_title
				}
			}
			title += website_title
			document.title = title;
		});
	}

	set_description(description = "") {
		if(description === null) {
			description = "";
		}
		document.querySelector('meta[name="description"]').setAttribute("content", description);
	}
}