import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "./auth.service";
import {ApiService} from "./api.service";
import {get_school_path} from "./school.service";

@Injectable({
	providedIn: 'root'
})
export class AdminAuthGuard {

	constructor(
		private authService: AuthService,
		private router: Router,
		public route: ActivatedRoute,
	) {
		this.authService.is_admin_panel = true;
		this.authService.user.is_admin = true;
		this.authService.init_token();
		this.authService.user.init();
	}

	async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		let lang = next.params.lang || localStorage.getItem('lang') || 'tc';
		let search = location.search;
		let url_search_params = new URLSearchParams(search);
		if (url_search_params.has('ro_jwt')) {
			let token = url_search_params.get('ro_jwt');
			let is_logged_in = await this.authService.login_with_ro_jwt(token);
			if (is_logged_in) {
				return true;
			} else {
				this.router.navigate([get_school_path(), lang, 'admin', 'login']);
				return false;
			}
		}

		console.log("is_admin_panel", this.authService.is_admin_panel);
		if (await this.authService.is_token_valid(true)) { // 檢查用戶是否已經登錄
			return true;
		} else {
			this.router.navigate([get_school_path(), lang, 'admin', 'login']); // 如果用戶未登錄，導航到登錄頁面
			return false;
		}
	}

}
