import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {GridOptions} from "ag-grid-community";
import moment from "moment";
import {AlertService} from "./alert.service";
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
import {StatusRenderer} from "../sharedModule/customAgGridModule/status.renderer";
import {NoRowsOverlay} from "../sharedModule/customAgGridModule/noRows.overlay";

const Singleton_Data_Center = {
	cache: {},
	expire_time: 1000 * 5,
	data_getters: {},
	queue: {},
	running_queue: [],
	get(key): Promise<any> {
		return new Promise(resolve => {
			this.queue[key] = this.queue[key] || [];
			this.queue[key].push(resolve);
			this.run_queue(key);
		});
	},
	async run_queue(key) {
		if (this.running_queue.includes(key)) {
			return;
		}

		this.running_queue.push(key);
		let queue = this.queue[key];
		if (queue) {
			let value = await this.actual_get(key);
			queue.forEach(resolve => resolve(value));
			delete this.queue[key];
		}
		this.running_queue = this.running_queue.filter(item => item !== key);
	},
	async actual_get(key) {
		if (!this.cache[key] || this.cache[key].expire_time < Date.now()) {
			let getter = this.data_getters[key];
			if (getter) {
				this.cache[key] = {
					expire_time: Date.now() + this.expire_time,
					value: await getter(),
				}
			}
		}
		return this.cache[key].value;
	},
	register(key, getter) {
		if (!this.data_getters[key]) {
			this.data_getters[key] = getter;
		}
	}
}


@Injectable({providedIn: 'root'})
export class CommonService {

	public MARK_UP_TYPE_MULTIPLE = 1;
	public MARK_UP_TYPE_PLUS = 2;
	public MARK_UP_TYPE_MINUS = 3;
	public MARK_UP_TYPE_FREE = 4;

	public PRICE_TABLE_ITEM_TYPE_SUBSCRIPTION = 1;
	public PRICE_TABLE_ITEM_TYPE_PRODUCT = 2;
	public PRICE_TABLE_ITEM_TYPE_BREAKDOWN = 3;
	public PRICE_TABLE_ITEM_TYPE_ITEM = 4;

	public DISCOUNT_TYPE_MULTIPLE = 1;
	public DISCOUNT_TYPE_PLUS = 2;
	public DISCOUNT_TYPE_MINUS = 3;

	constructor(
		private trans: TranslateService,
		private alert: AlertService,
		private data: ApiService,
		public auth: AuthService
	) {
	}

	timeout(ms = null) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	until(cb, delay = 100) {
		return new Promise(async resolve => {
			while (!cb()) {
				await this.timeout(delay);
			}
			resolve(true);
		});
	}

	public get_user_id() {
		return this.auth.jwt_obj.user_id;
	}

	public get_edit_form_title(id, item) {

		let key = 'erp.common.edit_form.';
		if (id) {
			key += 'edit_title';
		} else {
			key += 'create_title';
		}
		return this.trans.instant(key, {item})
	}

	public get_common_status_column_def(items = null) {
		if (!items) {
			items = [
				{titleKey: 'erp.common.status_1', value: 1, bgColor: '#7fc42d', color: '#fff'},
				{titleKey: 'erp.common.status_2', value: 2, bgColor: '#ee4865', color: '#fff'},
				{titleKey: 'erp.common.status_3', value: 3, bgColor: '#606060', color: '#fff'},
			]
		}
		return {
			headerName: this.trans.instant('erp.common.status'),
			field: 'status',
			minWidth: 130,
			cellRendererFramework: StatusRenderer,
			cellRendererParams: (params) => {
				return {
					items: items,
					output: params.data.status,
					wrapperStyle: {
						width: '110px',
					}
				}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				valueGetter: (params: any) => {
					if (!params.data.status) {
						return null
					}
					let result = params.data.status;
					items.find(item => {
						if (item.value === params.data.status) {
							result = item.titleKey;
							return true;
						}
					});
					return this.trans.instant(result);
				},
			},
		}
	}

	public get_accounting_theme() {
		return {
			"default": {
				"section-maincolor": "#2D8EC3",
				"popupform-rowbgcolor": "#FFFFFF",
				"popupform-faiconcolor": "#EAD5D9",
				"alert-bgcolor": "#fff",
				"section-maincolor10pt": "#944E5F1a",
				"section-maincolor20pt": "#944E5F2a",
				"section-titlecolor": "#000",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-bottomcolor": "#d0d0d0",
				"header-titlecolor": "#000",
				"header-studentinfobg": "#f7f7f7",
				"popuptoggle-bgcolor": "#fff",
				"sidepanel-bgcolor": "#f7f7f7",
				"sidepanel-buttonselectedbgcolor": "var(--section-maincolor)",
				"sidepanel-buttonoverbgcolor": "var(--common-buttonoverbgcolor)",
				"section-popupbgcolor": "#E4F3FB",
				"section-popupbgcolor2": "#D2E2DB",
				"popup-head-bg": "#FFFFFF",
				"popup-text": "#000000",
				"popupform-subtitlecolor": "#333333",
				"popupform-panelbgcolor": "#F5F5F5",
				"popupform-paneltextcolor": "#333333",
				"popupframe-linecolor": "rgba(0, 0, 0, 0.1)",
				"popupform-textcolor": "var(--section-maincolor)",
				"popupform-bgcolor": "#f1f1f7",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"aggrid-headerbgcolor": "#EDEDED",
				"aggrid-filterheaderbgcolor": "#EDEDED",
				"aggrid-linecolor": "var(--list-linecolor)",
				"aggrid-bordercolor": "#ccc",
				"aggrid-headertextcolor": "#666",
				"aggrid-textcolor": "#666",
				"aggrid-row-mouseover-bgcolor": 'rgba(223,238,240,0.4)',
				"menuitem-iconbadge": "url('/assets/img/redeem/icon_badge_dark.svg')",
				"menuitem-iconbadgehover": "url('/assets/img/redeem/icon_badge_white.svg')",
				"icon-avatar": "url('/assets/img/redeem/icon_prize_avatar.svg')",
				"icon-badge": "url('/assets/img/redeem/icon_prize_badge.svg')",
				"redeem-modalbgcolor": "#f7f3f4",
				"cancelBtn-bgcolor": "#5b5252",
				"giftBoxIconColor": "#EAD5D9",
				"mouseOverColor": "#cccccc",
				"details-bgcolor": "f7f3f4",
				"details-textcolor": "#5b5252",
				"alert-cancelbuttoncolor": "#BEBEBE",
				"reedem-record-faIcon-color": "#EAD5D9",
				"alert-okbuttoncolor": "var(--section-maincolor)",
			},
			"dark": {
				"popupform-rowbgcolor": "#1E1E29",
				"popupform-faiconcolor": "#38374F",
				"alert-bgcolor": "#2D2C43",
				"section-maincolor": "#3AA6E5",
				"section-maincolor10pt": "#944E5F1a",
				"section-maincolor20pt": "#944E5F2a",
				"section-titlecolor": "#fff",
				"pulldown-bgcolor": "#38374f",
				"pulldown-labelcolor": "#fff",
				"pulldown-bottomcolor": "#000",
				"header-titlecolor": "#fff",
				"header-studentinfobg": "#26253a",
				"popuptoggle-bgcolor": "#1e1e29",
				"sidepanel-bgcolor": "transparent",
				"sidepanel-buttonselectedbgcolor": "var(--section-maincolor)",
				"sidepanel-buttonoverbgcolor": "var(--common-buttonoverbgcolor)",
				"section-popupbgcolor": "#2D2C43",
				"section-popupbgcolor2": "#252537",
				"popup-head-bg": "#1E1E29",
				"popup-text": "#FFFFFF",
				"popupform-subtitlecolor": "#A0A0A0",
				"popupform-panelbgcolor": "#38374F",
				"popupform-paneltextcolor": "#FFFFFF",
				"popupframe-linecolor": "rgba(0, 0, 0, 0.1)",
				"popupform-textcolor": "#fff",
				"popupform-bgcolor": "#f1f1f7",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"aggrid-headerbgcolor": "#181821",
				"aggrid-filterheaderbgcolor": "#181821",
				"aggrid-linecolor": "#53536A",
				"aggrid-bordercolor": "#53536A",
				"aggrid-headertextcolor": "#A0A0A0",
				"aggrid-textcolor": "#A0A0A0",
				"aggrid-row-mouseover-bgcolor": 'rgba(223,238,240,0.4)',
				"menuitem-iconbadge": "url('/assets/img/redeem/icon_badge.svg')",
				"menuitem-iconbadgehover": "url('/assets/img/redeem/icon_badge_white.svg')",
				"icon-avatar": "url('/assets/img/redeem/icon_prize_avatar.svg')",
				"icon-badge": "url('/assets/img/redeem/icon_prize_badge.svg')",
				"redeem-modalbgcolor": "#222237",
				"cancelBtn-bgcolor": "#646481",
				"giftBoxIconColor": "#38374F",
				"mouseOverColor": "#8080A4",
				"details-bgcolor": "#222237",
				"details-textcolor": "#646481",
				"alert-cancelbuttoncolor": "#1E1E29",
				"reedem-record-faIcon-color": "#38374F",
				"alert-okbuttoncolor": "var(--section-maincolor)",
			}
		};
	}


	public get_default_theme() {
		return {
			"default": {
				"section-maincolor": "#873F9E",
				"popupform-rowbgcolor": "#FFFFFF",
				"popupform-faiconcolor": "#EAD5D9",
				"alert-bgcolor": "#fff",
				"section-maincolor10pt": "#944E5F1a",
				"section-maincolor20pt": "#944E5F2a",
				"section-titlecolor": "#000",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-bottomcolor": "#d0d0d0",
				"header-titlecolor": "#000",
				"header-studentinfobg": "#f7f7f7",
				"popuptoggle-bgcolor": "#fff",
				"sidepanel-bgcolor": "#f7f7f7",
				"sidepanel-buttonselectedbgcolor": "var(--section-maincolor)",
				"sidepanel-buttonoverbgcolor": "var(--common-buttonoverbgcolor)",
				"section-popupbgcolor": "#F1F1F7",
				"section-popupbgcolor2": "#D2E2DB",
				"popup-head-bg": "#FFFFFF",
				"popup-text": "#000000",
				"popupform-subtitlecolor": "#333333",
				"popupform-panelbgcolor": "#F5F5F5",
				"popupform-paneltextcolor": "#333333",
				"popupframe-linecolor": "rgba(0, 0, 0, 0.1)",
				"popupform-textcolor": "var(--section-maincolor)",
				"popupform-bgcolor": "#f1f1f7",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"aggrid-headerbgcolor": "#EDEDED",
				"aggrid-filterheaderbgcolor": "#EDEDED",
				"aggrid-linecolor": "var(--list-linecolor)",
				"aggrid-bordercolor": "#ccc",
				"aggrid-headertextcolor": "#666",
				"aggrid-textcolor": "#666",
				"aggrid-row-mouseover-bgcolor": 'rgba(223,238,240,0.4)',
				"menuitem-iconbadge": "url('/assets/img/redeem/icon_badge_dark.svg')",
				"menuitem-iconbadgehover": "url('/assets/img/redeem/icon_badge_white.svg')",
				"icon-avatar": "url('/assets/img/redeem/icon_prize_avatar.svg')",
				"icon-badge": "url('/assets/img/redeem/icon_prize_badge.svg')",
				"redeem-modalbgcolor": "#f7f3f4",
				"cancelBtn-bgcolor": "#5b5252",
				"giftBoxIconColor": "#EAD5D9",
				"mouseOverColor": "#cccccc",
				"details-bgcolor": "f7f3f4",
				"details-textcolor": "#5b5252",
				"alert-cancelbuttoncolor": "#BEBEBE",
				"reedem-record-faIcon-color": "#EAD5D9",
				"alert-okbuttoncolor": "var(--section-maincolor)",
			},
			"dark": {
				"popupform-rowbgcolor": "#1E1E29",
				"popupform-faiconcolor": "#38374F",
				"alert-bgcolor": "#2D2C43",
				"section-maincolor": "#AF67C4",
				"section-maincolor10pt": "#944E5F1a",
				"section-maincolor20pt": "#944E5F2a",
				"section-titlecolor": "#fff",
				"pulldown-bgcolor": "#38374f",
				"pulldown-labelcolor": "#fff",
				"pulldown-bottomcolor": "#000",
				"header-titlecolor": "#fff",
				"header-studentinfobg": "#26253a",
				"popuptoggle-bgcolor": "#1e1e29",
				"sidepanel-bgcolor": "transparent",
				"sidepanel-buttonselectedbgcolor": "var(--section-maincolor)",
				"sidepanel-buttonoverbgcolor": "var(--common-buttonoverbgcolor)",
				"section-popupbgcolor": "#2D2C43",
				"section-popupbgcolor2": "#252537",
				"popup-head-bg": "#1E1E29",
				"popup-text": "#FFFFFF",
				"popupform-subtitlecolor": "#A0A0A0",
				"popupform-panelbgcolor": "#38374F",
				"popupform-paneltextcolor": "#FFFFFF",
				"popupframe-linecolor": "rgba(0, 0, 0, 0.1)",
				"popupform-textcolor": "#fff",
				"popupform-bgcolor": "#f1f1f7",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"aggrid-headerbgcolor": "#181821",
				"aggrid-filterheaderbgcolor": "#181821",
				"aggrid-linecolor": "#53536A",
				"aggrid-bordercolor": "#53536A",
				"aggrid-headertextcolor": "#A0A0A0",
				"aggrid-textcolor": "#A0A0A0",
				"aggrid-row-mouseover-bgcolor": 'rgba(223,238,240,0.4)',
				"menuitem-iconbadge": "url('/assets/img/redeem/icon_badge.svg')",
				"menuitem-iconbadgehover": "url('/assets/img/redeem/icon_badge_white.svg')",
				"icon-avatar": "url('/assets/img/redeem/icon_prize_avatar.svg')",
				"icon-badge": "url('/assets/img/redeem/icon_prize_badge.svg')",
				"redeem-modalbgcolor": "#222237",
				"cancelBtn-bgcolor": "#646481",
				"giftBoxIconColor": "#38374F",
				"mouseOverColor": "#8080A4",
				"details-bgcolor": "#222237",
				"details-textcolor": "#646481",
				"alert-cancelbuttoncolor": "#1E1E29",
				"reedem-record-faIcon-color": "#38374F",
				"alert-okbuttoncolor": "var(--section-maincolor)",
			}
		};
	}

	public calc_ag_grid_height(rows: any[], row_height = 50, offset = 103, empty_height = 500) {
		return rows.length ? rows.length * row_height + offset : empty_height;
	}

	public get_default_ag_grid_options(row_id): GridOptions {
		return {
			getRowId: data => {
				return data[row_id];
			},
			suppressRowClickSelection: true,
			headerHeight: 50,
			floatingFiltersHeight: 50,
			rowHeight: 50,
			defaultColDef: {
				editable: false,
				sortable: false,
				filter: true,
				floatingFilter: true,
				resizable: true,
				suppressMenu: true,
				cellStyle: {display: "inline-flex", alignItems: "center"},
			},
			noRowsOverlayComponent: NoRowsOverlay,
			noRowsOverlayComponentParams: {text: 'common.no_data'},
		}
	}

	public get_common_status_options() {
		return [
			{label: this.trans.instant('erp.common.status_1'), value: 1},
			{label: this.trans.instant('erp.common.status_2'), value: 2},
			{label: this.trans.instant('erp.common.status_3'), value: 3},
		];
	}

	public process_form_error(form, label) {

		console.log(form);
		let error_msg_list = [];
		let errors = {};
		let required_fields = [];
		for (let field in form.controls) {
			let field_errors: any = {};
			let control = form.controls[field];
			if (control.errors) {
				field_errors = {...control.errors};
				if (field_errors.required) {
					required_fields.push(label[field]);
				}
				if (field_errors.min) {
					error_msg_list.push(
						this.trans.instant('erp.common.error.min', {
							item: label[field],
							min: field_errors.min.min
						})
					);
				}
			}
			errors[field] = field_errors;
		}
		if (error_msg_list.length) {
			this.alert.alert(error_msg_list.join("\n"));
		}
		if (required_fields.length) {
			let split = this.trans.instant('erp.common.split');
			console.log({required_fields})
			this.alert.toastError(
				this.trans.instant('erp.common.error.required', {item: required_fields.join(split)})
			)
		}

		return errors;
	}

	public get_country_code_options() {
		let list = this.trans.instant('country');

		let options = [];
		for (let key in list) {
			options.push({label: `${key} - ${list[key]}`, value: key});
		}
		return options;
	}

	public async get_user_options() {
		let list = await Singleton_Data_Center.get('user_list');

		return list.map((item) => {
			return {label: item.display_name, value: item.user_id}
		});
	}

	public async get_company_options() {
		let response = await this.data.post('/api/sales/company/list', {});
		if (response) {
			return response.list.map(item => {
				return {
					label: item.name,
					value: item.company_id,
				}
			});
		}
		return [];
	}

	public get_deal_progress_options(allow_null = false) {
		return [
			...(allow_null ? [{label: this.trans.instant('erp.common.please_select'), value: null}] : []),
			{label: this.trans.instant('erp.deal.progress_1'), value: 1},
			{label: this.trans.instant('erp.deal.progress_2'), value: 2},
			{label: this.trans.instant('erp.deal.progress_3'), value: 3},
			{label: this.trans.instant('erp.deal.progress_4'), value: 4},
		];
	}

	public format_datetime(value, format = 'YYYY-MM-DD HH:mm:ss') {
		if (!value) {
			return null;
		}

		return moment(value).format(format);
	}

	public async singleton_get(key) {
		return await Singleton_Data_Center.get(key);
	}

	public async get_contact_options(company_id = null) {
		let list: any[] = await Singleton_Data_Center.get('contact_list');

		return list
			.filter(item => {
				if (company_id === null) {
					return true;
				}

				return item.company_id === company_id
			})
			.map(item => {
				return {
					label: item.name,
					value: item.contact_id,
				}
			});

	}

	public money_format(value) {
		if (value == null) {
			value = 0;
		}

		if (typeof value === 'string') {
			value = Number.parseFloat(value);
		}
		let format = new Intl.NumberFormat('zh-HK', {
			style: "decimal",
			maximumFractionDigits: 1,
		})
		value = format.format(value);
		return `$${value}`
	}

	public async confirm(msg) {
		try {
			return await this.alert.confirm(msg);
		} catch (e) {
			return false;
		}
	}

	public async delete_alert(msg) {
		try {
			let result = await this.alert.deleteAlert(msg);
			return result === 'delete';
		} catch (e) {
			return false;
		}
	}

	public read_as_data_url(file) {
		return new Promise(resolve => {
			let reader = new FileReader();
			reader.onload = (e) => {
				resolve(reader.result);
			}
			reader.readAsDataURL(file);
		})
	}

	public async data_url_to_file(data_url, filename) {

		var byte_string = atob(data_url.split(',')[1]);

		var mime_string = data_url.split(',')[0].split(':')[1].split(';')[0];

		var ab = new ArrayBuffer(byte_string.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byte_string.length; i++) {
			ia[i] = byte_string.charCodeAt(i);
		}
		let blob = new Blob([ab], {type: mime_string});

		return new File([blob], filename, {type: mime_string});
	}

	public download_file(file) {
		let a = document.createElement('a');
		a.href = URL.createObjectURL(file);
		a.download = file.name;
		a.target = '_blank';
		a.click();
		URL.revokeObjectURL(a.href);
	}

	public download_url(url, filename) {
		let a = document.createElement('a');
		a.href = url;
		a.download = filename;
		a.target = '_blank';
		a.click();
	}


	public calc_price_table_item_price(item) {

		let {qty, product_rate, mark_up, mark_up_type, child_items} = item;

		let has_child_items = child_items && child_items.length;

		product_rate *= 1;

		mark_up *= 1;

		if (has_child_items) {
			let total = 0;
			child_items.forEach(child_item => {
				this.calc_price_table_item_price(child_item);
				total += child_item.price;
			});
			item.product_rate = total;
			item.price = total * qty;
		} else {
			item.price = product_rate * qty;
		}

		if (mark_up_type === this.MARK_UP_TYPE_FREE) {
			item.price = 0;
		}

		if (mark_up_type === this.MARK_UP_TYPE_MULTIPLE) {
			item.price = item.price * mark_up;
		}

		if (mark_up_type === this.MARK_UP_TYPE_PLUS) {
			item.price = item.price + mark_up;
		}

		if (mark_up_type === this.MARK_UP_TYPE_MINUS) {
			item.price = item.price - mark_up;
		}
	}

	calc_discount(item) {
		let {sub_total, discount_type, discount_value} = item;
		sub_total *= 1;
		discount_value *= 1;

		if (discount_type === this.DISCOUNT_TYPE_MULTIPLE) {
			item.discount = sub_total * (1 - discount_value);
		}

		if (discount_type === this.DISCOUNT_TYPE_PLUS) {
			item.discount = -discount_value;
		}

		if (discount_type === this.DISCOUNT_TYPE_MINUS) {
			item.discount = discount_value;
		}
	}

	calc_price_table_item_commission(item) {
		let value = item.commission_value || 0;
		let type = item.commission_type;
		let price = item.price;
		let amount = 0;

		if (type === 1) {
			// percent
			amount = price * value / 100;
		} else if (type === 2) {
			// fixed amount
			amount = value;
		}

		item.commission_amount = amount;
	}

	/**
	 *
	 * use 'asc' or 'desc' to sort
	 *
	 * @param {Array} list
	 * @param {Object} keys
	 * @return {Array}
	 *
	 * @example sort_by_keys(list, {name: 'asc', age: 'desc'})
	 */
	sort_by_keys(list, keys) {
		return list.sort((a, b) => {
			for (let key in keys) {
				let order = keys[key];
				let value_a = a[key];
				let value_b = b[key];
				if (value_a > value_b) {
					return order === 'asc' ? 1 : -1;
				}
				if (value_a < value_b) {
					return order === 'asc' ? -1 : 1;
				}
			}
			return 0;
		});
	}

	/**
	 *
	 * @param {Array} list
	 * @param {function|string} key
	 * @param {function|string|null} value
	 */
	to_map(list, key, value = null) {
		let map = {};
		list.forEach(item => {
			let item_key = typeof key === 'function' ? key(item) : item[key];
			let item_value = item;
			if (value !== null) {
				item_value = typeof value === 'function' ? value(item) : item[value];
			}
			map[item_key] = item_value;
		});
		return map;
	}

	to_options(list, value, label) {
		return list.map(item => {

			let item_value = typeof value === 'function' ? value(item) : item[value];
			let item_label = typeof label === 'function' ? label(item) : item[label];

			return {
				label: item_label,
				value: item_value,
			}
		});
	}

	get_color_generator() {
		return get_color_generator();
	}

	array_toggle(arr, item) {
		if (arr.includes(item)) {
			arr.splice(arr.indexOf(item), 1);
		} else {
			arr.push(item);
		}
	}

	pick_file(
		options: {
			accept?: string,
			multiple?: boolean,
		} = {}
	): Promise<any> {
		return new Promise((resolve, reject) => {
			let input = document.createElement('input');
			input.type = 'file';
			input.accept = options.accept || '*';
			input.multiple = options.multiple || false;
			input.style.display = 'none';
			input.onchange = () => {
				document.body.removeChild(input);
				if (input.files.length) {
					if (options.multiple) {
						resolve(Array.from(input.files));
					} else {
						resolve(input.files[0]);
					}
				} else {
					resolve(null);
				}
			}
			document.body.appendChild(input);
			input.click();
		});
	}

	public z_index = 200;

	get_z_index() {
		return this.z_index++;
	}

	public array_is_last(arr, item) {
		return arr.indexOf(item) === arr.length - 1;
	}
}


// create a generator function that generate color code randomly.
function* get_color_generator() {
	let r = 255;
	let g = 0;
	let b = 0;

	let used_colors = [];

	// return rainbow color first
	let rainbow_colors = [
		'#FD6585',
		'#FECC60',
		'#3DA3E8',
		'#51C0BF',
		'#9A76E9',
		'#FD9E4B',
		'#82C947',
		'#999999',
	];

	while (true) {
		for (let color of rainbow_colors) {
			yield color;
		}
	}

	// used_colors = used_colors.concat(rainbow_colors);
	//
	// while (true) {
	// 	r = Math.floor(Math.random() * 255);
	// 	g = Math.floor(Math.random() * 255);
	// 	b = Math.floor(Math.random() * 255);
	//
	// 	let hex = '#' + r.toString(16).padStart(2, '0') + g.toString(16).padStart(2, '0') + b.toString(16).padStart(2, '0');
	// 	if (!used_colors.includes(hex)) {
	// 		used_colors.push(hex);
	// 		yield hex;
	// 	}
	//
	// }
}