import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {get_school_id} from "./school.service";

@Injectable({providedIn: 'root'})
export class ApiService {

	public token: string = null;
	public lang: string = 'tc';

	public common_extra_headers: any = {};

	constructor() {
		this.common_extra_headers.school_id = `${get_school_id()}`;
	}

	public get(url, data = {}) {
		return new Promise(async (resolve, reject) => {
			let api_url = this.processUrl(url);
			// console.log(api_url);

			// change to fetch
			let response = await fetch(api_url, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${this.token}`,
					'lang': this.lang,
					...this.common_extra_headers
				}
			});
			resolve(await response.json());
		});
	}

	public post(url, data = null):Promise<any> {
		return new Promise(async (resolve, reject) => {
			// console.log('post', this.common_extra_headers)
			let api_url = this.processUrl(url);
			let response = await fetch(api_url, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${this.token}`,
					'Content-Type': 'application/json;charset=UTF-8',
					// set lang
					'lang': this.lang,
					...this.common_extra_headers
				},
				body: data ? JSON.stringify(data) : null
			});

			resolve(await response.json());
		});
	}

	public processUrl(url) {
		return `${environment.apiUrl}${url}`;
	}

}
