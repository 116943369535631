import {LanguageFileSetting} from "./TranslateModuleLoader";

export const TranslateProvider = {
	get(files: string[] = []) {
		return {
			provide: LanguageFileSetting,
			useValue: {
				basePath: "./assets/langs/",
				languages: [...files]
			}
		};
	},
}