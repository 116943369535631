export const environment = {
	production: true,
	type:"production",
	title: "Online Shop",
	baseHref: "/",
	version: require('../../package.json').version,
	versions: require('../../package.json').versions,
	serverHost: "https://www.rainbowone.shop",
	apiUrl: "https://www.rainbowone.shop/backend",
};
