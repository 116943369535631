import {NgModule} from '@angular/core';

import {JoyToastComponent} from './joy-toast.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CommonModule} from "@angular/common";

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule
	],
	exports: [JoyToastComponent],
	declarations: [JoyToastComponent],
	providers: [],
})
export class JoyToastModule {
}
