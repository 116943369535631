import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {SettingService} from './setting.service';

@Injectable({providedIn: 'root'})
export class ResetDescriptionGuard {
	constructor(
		public setting: SettingService,
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.setting.set_description();

		return true;
	}
}