import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {UserService} from "./user.service";
import {get_school_id, SchoolService} from "./school.service";

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private is_logged_in_var = false;
	private _token: string = null;
	public jwt_obj = null;
	private _device_token = null;
	public require_login_callback = null;
	public is_admin_panel = false;

	constructor(
		private api: ApiService,
		public user: UserService,
		public school:SchoolService,
	) {
		this.init_token();
	}

	init_token() {
		this.token = localStorage.getItem(this.token_key) || null;
		this.device_token = localStorage.getItem('device_token') || this.uuid_v4();

		this.is_logged_in_var = !!this.token;
	}


	get token_key() {
		if (this.is_admin_panel) {
			return `joylearn_${get_school_id()}_admin_token`;
		}
		return `joylearn_${get_school_id()}_token`;
	}

	set token(value) {
		this._token = value;
		if (!value) {
			this.api.token = null;
			localStorage.removeItem(this.token_key);
			this.jwt_obj = null;
		} else {
			this.api.token = value;
			localStorage.setItem(this.token_key, value);
			this.jwt_obj = this.decode_jwt_token(value);
		}
	}

	get token() {
		return this._token;
	}

	set device_token(value) {
		this._device_token = value;
		localStorage.setItem('device_token', value);
	}

	get device_token() {
		return this._device_token;
	}

	async admin_login(email: string, password: string): Promise<boolean> {
		this.is_admin_panel = true;
		this.user.is_admin = true;
		return this._login('/api/admin/user/login', email, password);
	}

	async login(email: string, password: string): Promise<boolean> {
		return this._login('/api/user/login', email, password);
	}

	async _login(api, email, password) {

		if (!email || !password) {
			return false;
		}

		let {ro_host, ro_api} = await this.api.post('/api/user/get_login_config');

		let ro_login_response: any = await fetch(ro_host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				api: ro_api,
				json: JSON.stringify([email, password, this.get_device_type(), this.device_token, true, '2.8.210', 'hk.openknowledge.rainbowone'])
			})
		});
		ro_login_response = await ro_login_response.json();

		if (ro_login_response.code !== 0) {
			return false;
		}

		if (`${ro_login_response.account.school_id}` !== `${this.school.school_id}`) {
			return false;
		}

		let response: any = await this.api.post(api, {
			login_response: ro_login_response,
			school_id: this.school.school_id,
		})

		if (response.token) {
			this.is_logged_in_var = true; // 假設驗證成功
			this.token = response.token;
			this.user.set_display_name(response.display_name);
			this.user.set_profile_image_url(response.profile_image_url);
			this.user.set_email(response.account.uacc_email);
		}
		return this.is_logged_in_var;
	}

	async admin_logout(): Promise<void> {
		// 在這裡實現用戶登出邏輯
		// 將 isLoggedInVar 設置為 false
		this.api.post('/api/admin/user/logout');
		this.is_logged_in_var = false;
		this.token = null;
		this.user.clear_data();
	}

	async logout(): Promise<void> {
		// 在這裡實現用戶登出邏輯
		// 將 isLoggedInVar 設置為 false
		this.api.post('/api/user/logout');
		this.is_logged_in_var = false;
		this.token = null;
		this.user.clear_data();
	}

	is_logged_in(): boolean {
		// 返回 isLoggedInVar 的值
		return this.is_logged_in_var;
	}

	decode_jwt_token(token: string): any {
		let payload = token.split('.')[1];
		payload = atob(payload);
		return JSON.parse(payload);
	}

	async get_user_profile(): Promise<any> {
		// 在這裡實現獲取用戶資料邏輯
		// 如果用戶已經登入，返回用戶資料
		// 如果用戶未登入，返回 null
		if (this.is_logged_in()) {
			let response: any = await this.api.post('/api/user/profile');
			if (response.success) {
				return response;
			}
		}
		return null;
	}

	uuid_v4() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = Math.random() * 16 | 0,
				v = c === 'x' ? r : (r & 0x3 | 0x8);

			return v.toString(16);
		});
	}

	get_device_type() {
		if (/iPad/.test(navigator.userAgent))
			return "iPad";
		if (/iPhone/.test(navigator.userAgent))
			return "iPhone";
		if (/Android/.test(navigator.userAgent))
			return "Android";
		if (/Windows/.test(navigator.userAgent))
			return "Windows";
		if (/Mac/.test(navigator.userAgent))
			return "Mac";
		return "Other";
	}

	public require_login() {
		if (this.require_login_callback) {
			return this.require_login_callback();
		}
	}

	async login_with_ro_jwt(ro_jwt) {
		let response = await this.api.post('/api/admin/user/login_with_ro_jwt', {
			ro_jwt: ro_jwt,
		});

		if (response.token) {
			this.is_logged_in_var = true; // 假設驗證成功
			this.token = response.token;
			this.user.set_display_name(response.display_name);
			this.user.set_profile_image_url(response.profile_image_url);
			this.user.set_email(response.uacc_email);
		}

		return this.is_logged_in_var;
	}

	async is_token_valid(is_admin = false) {
		let response = await this.api.post('/api/user/is_token_valid', {
			is_admin: is_admin,
		});
		return response.success;
	}
}
