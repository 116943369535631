import {Component, OnInit} from '@angular/core';
import {faCircleCheck, faCircleXmark} from "@fortawesome/pro-solid-svg-icons";
import {ToastService} from "../../../service/toast.service";

@Component({
	selector: 'joy-toast',
	template: `
		<div class="toast-container" [ngClass]="{show: queue.length > 0}">
			<ng-container *ngFor="let item of queue">
				<div class="toast" [class.success]="item.type === 'success'" [class.error]="item.type === 'error'">
					<fa-icon [icon]="icon_map[item.type]"></fa-icon>
					<div class="msg">
						{{ item.msg }}
					</div>
				</div>
			</ng-container>
		</div>
	`,
	styleUrls: ['./joy-toast.component.scss']
})

export class JoyToastComponent implements OnInit {

	queue = [];

	icon_map = {
		success: faCircleCheck,
		error: faCircleXmark,
	};

	constructor(
		public toast: ToastService,
	) {
	}

	ngOnInit() {
		this.toast.callback = (data) => this.add(data);
		console.log('toast init');
	}


	add(data: any) {
		let host = this;
		return new Promise((resolve, reject) => {
			data.msg = data.msg || '';
			data.interval = data.interval || 3000;
			data.type = data.type || 'success';

			let default_data = {
				msg: '',
				interval: 3000,
				type: 'success',
				...data,
				interval_id: null,
				show() {
					console.log('show')
					if (this.interval_id) {
						return;
					}

					this.interval_id = setTimeout(() => {
						this.remove();
					}, this.interval);
				},
				remove() {
					console.log('remove')
					clearTimeout(this.interval_id);
					this.interval_id = null;
					let index = host.queue.indexOf(this);
					if (index > -1) {
						host.queue.splice(index, 1);
						resolve(true);
					}
				},
			};


			host.queue.push(default_data);

			console.log(host.queue);

			default_data.show();

		});
	}
}