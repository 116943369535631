import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AlertService {

	constructor() {
	}

	confirm(msg) {
		return new Promise((resolve, reject) => {
			if (confirm(msg)) {
				resolve(true);
			} else {
				resolve(false);
			}
		});
	}

	alert(msg) {
		alert(msg);
	}

	toastError(msg) {
		alert(msg);
	}

	deleteAlert(msg) {
		return this.confirm(msg);
	}
}