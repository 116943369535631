import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {HttpClientModule} from "@angular/common/http";
import {TranslateModuleLoader} from "./common/TranslateModuleLoader";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {JoyToastModule} from "./sharedModule/common/ToastModule/Toast.module";
import {TranslateProvider} from "./common/TranslateProvider";
import {get_school_path} from "./service/school.service";

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		TranslateModuleLoader.forRoot(),
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		JoyToastModule,
	],
	providers: [
		TranslateProvider.get(['shop', 'primeng'])
	],
	bootstrap: [AppComponent]
})
export class AppModule {

	constructor() {
		let school_path = get_school_path();
		console.log('pathname', location.pathname);
		if (school_path === '') {
			if (location.pathname === '/') {
				location.pathname = '/tc';
			}
		} else if (location.pathname === `/${school_path}` || location.pathname === `/${school_path}/`) {
			location.pathname = `/${school_path}/tc`;
		}
	}
}
